body {
  font-family: $base-font-family;
}

.background-brand {
  background: $brand-background;
}

.background-gradient, .color-gradient {
  background: $gradient-background;
}
.color-gradient {
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.btn.btn-gradient {
  background: $gradient-background;
  color: #fff;
  border: none;
}
.btn.btn-gradient:hover {
  background: #fff;
  color: #555;
}

div.navbar.navbar-dark.bg-dark, #navbarHeader {
  background-color: #222222 !important;
}

#navbarHeader.bg-dark .text-muted {
  color: #fff !important;
}
#navbarHeader .text-muted a {
  color: #fff;
  font-weight: 600;
}

header > .navbar > .container a {
  padding: 0;
}
header > .navbar > .container a strong {
  font-size: 1.3em;
}
header > .navbar > .container a img {
  background: #fff;
  height: 1.7em;
  margin-right: 0.5em;
}

@media screen and (max-width: 440px) {
  header > .navbar {
    padding-left: 0;
    padding-right: 0;
  }
  header > .navbar > .container a strong {
    font-size: 1em;
  }
}

body > footer.container {
  padding: 0 !important;
}
body > footer > div {
  padding: 1em 0.5m;
}
footer .linkbar {
  padding: 1em 0;
  background: #efefef97;
}
footer .linkbar .copyright {
  float: right;
}
footer .linkbar a {
  margin-right: 2em;
  color: rgb(108, 117, 125);
}
footer .linkbar a:hover {
  color: rgb(108, 117, 125);
}

h3 {
  font-size: 1.5rem;
}

a {
  color: $brand-color;
}

.container {
  max-width: 1200px;
}
.container-alert {
  margin-top: 2em;
}
.container > h2 {
  margin-top: 2em;
}

.container.lowlight {
  background-color: $grey-color-light;
}
.container.highlight {
  background-color: $brand-color;
  color: $background-color;
}

blockquote {
  background: $grey-color-lighter;
  border: solid 0.1em $grey-color;
  border-left: solid 0.3em $grey-color;
  border-radius: 0.3em;
  padding: 0.3em;
  padding-left: 1.5em;
  size: 1.1em;
  font-weight: 500;
}
blockquote strong {
  font-weight: 700;
}
blockquote > p {
  margin: 0;
}

/*
 * Custom translucent site header
 */

.site-header {
  background-color: #000000;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.site-header a {
  color: #999;
  transition: ease-in-out color .15s;
}
.site-header a:hover {
  color: #fff;
  text-decoration: none;
}

/*
 * Dummy devices (replace them with your own or something else entirely!)
 */

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}


/*
 * Extra utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }
.box-shadow > img, .box-shadow > a > img { width: 100% }

.flex-equal > * {
  -ms-flex: 1;
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    flex: 1;
  }
}

.overflow-hidden { overflow: hidden; }


.bg-primary h2 a, .bg-primary h2 a:hover {
  color: #fff;
  text-decoration: none;
}
.bg-light h2 a, .bg-light h2 a:hover {
  color: #333;
  text-decoration: none;
}

.experience-logos img {
  width: 95%;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
      margin-bottom: $spacing-unit;
  }
}

.post-header > img {
  width: 100%;
  margin-bottom: 1.3em;
  border-radius: 0.25em;
}

.post-meta, p.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
  margin-bottom: 0.5em;
}

.post-content img {
  max-width: 100%;
}


.post table th {
  background: $brand-background;
  color: #fff;
  border: solid 0.1em rgba(0,0,0,0.5);
} 
.post table td {
  border: solid 0.1em rgba(0,0,0,0.5);
}

.recent h5 {
  font-size: 1em;
}
.recent ul li img {
  width: 100%;
  padding-bottom: 0.3em;
}


#sidebar {
  border-left: solid 0.1em $grey-color-light;
  padding-top: 0.5em;
}
#sidebar h4 {
  font-size: 1.3em;
}

/*
#sidebar .recent ul li,
#sidebar .related ul li {
  border: solid 0.1em $grey-color-light;
  border-radius: 0.5em;
  padding-left: 0.5em;
  padding-top: 0.5em;
  box-shadow: 0.3em 0.2em 0.2em $grey-color-light;
}
*/


#archives > hr {
  margin-bottom: 2em;
}
#archives > .archive-group .post-meta {
  margin-bottom: 0;
}
#archives > .archive-group h3 {
  margin-bottom: 1em;
}



.text-bg-primary {
  background-color: #005597 !important;
}




.card {
  width: 100%;
  padding: 0;
  margin: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 2px 0px, rgba(0, 0, 0, 0.14) 0px 2px 4px 0px;
}
.card a {
  text-decoration: none;
}
.card a:hover {
  text-decoration: underline;
}

.card .card-img-top {
  border-bottom: 0.1em solid #cccc
}




/* Copy button on Prism pre/code */
pre.highlight .copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
}
