@charset "utf-8";


// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #041fa3;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-lighter: #f8f8f8;
$grey-color-dark:  darken($grey-color, 25%);

$on-palm:          600px;
$on-laptop:        800px;

$brand-background: radial-gradient(rgba(100,100,100,0.1),rgba(0,0,0,0.5)), rgb(48, 94, 192);
$gradient-background: linear-gradient(135deg, #1e30f3 0%, #e21e80 100%);




// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "site"
;
